<template>
	<v-container>
		<h1>Викладачі</h1>
	</v-container>
</template>

<script>
import { mapActions } from 'vuex'

import User from '../models/User'

export default {
	computed: {
		users() {
			return User.all()
		},
	},
	methods: {
		...mapActions('notification', [
			'notify',
		]),
		async loadUsers() {
			try {
				const params = {
					role: 1,
				}
				await User.fetchGetAll(params)
			} catch (error) {
				this.notify({
					text: 'Не вдалося завантажити список викладачів',
					color: 'error',
				}, { root: true })
			}
		},
	},
	mounted() {
		this.loadUsers()
	},
}
</script>